<template>
	<layout-main :show-header="true">
		<div
			class="text-2xl md:text-3xl mt-8 font-thin md:px-4 text-center md:text-left"
		>
			2024 Season Leaderboard
		</div>

		<div
			class="text-lg mt-4 p-4 mx-4 md:mx-0 rounded-lg bg-gray-100 grid gap-4 grid-cols-[5rem,1fr]"
		>
			<div class="text-blue-800 text-center py-2">
				<fa icon="fa-solid fa-info-circle" class="size-14" />
			</div>
			<div class="self-center">
				This season's leaderboard is based on aggregate points earned
				across the

				<a
					href="https://set-builder.collecthth.com/hth-football-2k24"
					class="underline hover:no-underline underline-offset-2 text-teal-800"
					>Football 2024</a
				>
				season.
			</div>
		</div>

		<div
			class="grid grid-cols-[5fr,2fr,2fr] mt-6 mx-4 divide-y divide-y-gray-200"
		>
			<div class="grid grid-cols-subgrid col-span-3 text-base/[2.5rem]">
				<div class="text-left font-semibold pl-2">Rank / User</div>
				<div class="text-right font-semibold">Total Points</div>
				<div class="text-right font-semibold pr-2">Entries Earned</div>
			</div>

			<template v-for="row in paginatedData">
				<div
					class="grid grid-cols-subgrid col-span-3 text-base/[2.5rem]"
				>
					<div
						class="text-left font-normal py-2 pl-2 whitespace-nowrap truncate"
					>
						<div
							class="inline-block size-12 rounded-full text-sm/[3rem] text-center mr-2 font-bold"
							:class="[rankClasses(Number(row.rank))]"
						>
							{{ row.rank }}
						</div>
						{{ row.account_identifier }}
						<div
							v-if="
								row.account_identifier ===
								authStore.user?.username
							"
							class="inline-block bg-green-600 text-white uppercase text-xs align-middle px-2 py-1 rounded-md ml-2"
						>
							You
						</div>
					</div>
					<div class="text-right py-2">
						{{ row.total_points.toLocaleString() }}
					</div>
					<div class="text-right py-2 pr-2">
						{{ entriesEarned(Number(row.rank)).toLocaleString() }}
					</div>
				</div>
			</template>
		</div>
	</layout-main>
</template>

<script lang="ts" setup>
import { usePickSixStore } from '@/stores/PickSixStore'
import { Season, SeasonResponse } from '@/types/Season'
import { computed, onBeforeMount, ref } from 'vue'
import { useAuthStore } from '@/stores/AuthStore'
import { LeaderboardResponse, LeaderboardRow } from '@/types/Leaderboard'
import { useLogger } from '@/modules/log'

const authStore = useAuthStore()
const log = useLogger()

const pickSixStore = usePickSixStore()

const season = ref<Season | null>(null)
const leaderboard = ref<LeaderboardRow[]>([]) // Initialize with an empty array

const currentPage = ref(1)
const rowsPerPage = 250

const paginatedData = computed(() => {
	const start = (currentPage.value - 1) * rowsPerPage
	const end = start + rowsPerPage
	return leaderboard.value.slice(start, end)
})

function rankClasses(rank: number): string {
	if (rank == 1) {
		return 'bg-gradient-to-br from-[#e8e2b2] via-[#a8811f] to-[#e8e2b2] text-white shadow-lg'
	}

	if (rank == 2) {
		return 'bg-gradient-to-br from-zinc-800 via-zinc-900 to-zinc-800 text-white shadow-lg'
	}

	if (rank == 3) {
		return 'bg-gradient-to-br from-[#A97142] via-[#A94D00] to-[#A97142] text-white shadow-lg'
	}

	return 'bg-gray-200'
}

function entriesEarned(rank: number): number {
	const def = [
		[1, 5, 1000],
		[6, 10, 500],
		[11, 25, 350],
		[26, 50, 250],
		[51, 75, 200],
		[76, 100, 100],
		[101, 250, 50],
	]

	for (const v of def) {
		if (rank >= v[0] && rank <= v[1]) {
			return v[2]
		}
	}

	return 1
}

onBeforeMount(async () => {
	await authStore.getAccount()

	pickSixStore
		.currentSeason()
		.then((response: SeasonResponse): Season => {
			if (response.success) {
				season.value = response.season!
				return response.season!
			}
			throw new Error('Failed to load season')
		})
		.then(async (szn: Season): Promise<void> => {
			return await seasonLeaderboard(szn.slug as string)
		})
})

async function seasonLeaderboard(seasonSlug: string) {
	await pickSixStore
		.seasonLeaderboard(seasonSlug)
		.then((response: LeaderboardResponse) => {
			if (response.success) {
				leaderboard.value = response.leaderboard!
				return
			}
			throw new Error('Failed to load user entries')
		})
		.catch((err) => {
			log.error('ViewEntries: failed to load user data', {
				err: err.message,
				season: season.value,
				leaderboard: leaderboard.value,
			})
		})
}
</script>
