import * as api from '@/api'
import { AccountDetailsResponse } from '@/types/AccountDetails'
import { ListEntryResponse, SaveEntryResponse } from '@/types/Entry'
import { LeaderboardResponse } from '@/types/Leaderboard'
import { SeasonResponse } from '@/types/Season'

import { defineStore } from 'pinia'

export const usePickSixStore = defineStore('pickSix', () => {
	async function currentSeason(): Promise<SeasonResponse> {
		return api.pick6.currentSeason()
	}

	async function seasonLeaderboard(
		seasonSlug: string,
	): Promise<LeaderboardResponse> {
		return api.pick6.seasonLeaderboard(seasonSlug)
	}

	async function accountDetails(): Promise<AccountDetailsResponse>{
		return api.account.accountDetails()
	}

	async function createEntry(
		seasonSlug: string,
		weekId: string,
		heroIds: string[],
		entryCount: number,
	): Promise<SaveEntryResponse> {
		return api.account.createEntry(seasonSlug, weekId, entryCount, heroIds)
	}

	async function deleteEntry(entryId: string): Promise<DeleteEntryResponse> {
		return api.account.deleteEntry(entryId)
	}

	async function loadEntries(
		seasonSlug: string,
		weekId: string | null = null,
	): Promise<ListEntryResponse> {
		return api.account.entries(seasonSlug, weekId)
	}

	function init() {
		return {
			// actions
			createEntry,
			deleteEntry,
			loadEntries,
			currentSeason,
			seasonLeaderboard,
			accountDetails,
		}
	}

	return init()
})
