<template>
	<div v-if="authStore.user" class="flex items-center gap-4">
		<router-link :to="{ name: 'account' }">
			<div class="group flex items-center gap-1">
				<img
					:src="`https://id.blokpax.com/avatar/@${authStore.user.username}?size=128`"
					:alt="`@${authStore.user.username}`"
					class="size-10 rounded-full border-2 border-white/60 group-hover:border-white/100 transition-colors"
				/>
				<div class="font-medium tracking-tight select-none text-white">
					@{{ authStore.user.username }}
				</div>
			</div>
		</router-link>
		<div>
			<button
				@click.stop="logout"
				title="Log out"
				class="text-white/50 hover:text-white"
			>
				<fa icon="fa-solid fa-right-from-bracket" />
			</button>
		</div>
	</div>
	<div v-else class="flex items-center">
		<a href="/auth/login">Login</a>
	</div>
</template>
<script lang="ts" setup>
import { useAuthStore } from '@/stores/AuthStore'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'

const authStore = useAuthStore()
const route = useRoute()

const emit = defineEmits<{
	(e: 'logout'): void
}>()

onMounted(async () => {
	if (!authStore.authenticated) {
		await authStore.getAccount()
	}
})

async function logout() {
	emit('logout')
}
</script>
